<template>
    <div>
        <div class="table-responsive" style="height: 540px; overflow-y: auto">
            <table class="table mb-0 table-bordered table-box custom__table--sticky">
                <thead>
                    <tr>
                        <th>大項目</th>
                        <th>中項目</th>
                        <th>小項目</th>
                        <th>詳細</th>
                        <th>パートナー</th>
                        <th>ツール</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(itemTscope, kScope) in dataTools"
                        :key="`${kScope}=>kScope3=>kScope2}=>kScope1`"
                        :class="`${itemTscope?.end_line ? 'end--scoppe__tool' : ''}`"
                    >
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.parent?.parent?.parent?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.parent?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.parent?.parent?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.parent?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.parent?.parent?.key == 'medium_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.parent?.key == 'medium_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.key == 'medium_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.parent?.key == 'small_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.key == 'small_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.key == 'detail_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left" style="width: 320px">
                            <div style="max-width: 320px">
                                {{ itemTscope.partner?.name ?? '' }}
                            </div>
                        </td>
                        <td class="text-left" style="width: 320px">
                            <div style="max-width: 320px">
                                {{ itemTscope?.tool?.tool ?? '' }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableTools',
    props: {
        dataTools: Array,
        default: () => []
    },

    data() {
        return {
            grouped: {}
        };
    },

    methods: {
        onHandleMapData(paramNewTool) {
            paramNewTool?.forEach((item) => {
                let masterOrderScope = item.master_order_scope;
                // Tìm large_item (cấp cao nhất)
                while (masterOrderScope.parent !== null) {
                    masterOrderScope = masterOrderScope.parent;
                }
                const largeItemId = masterOrderScope?.id;
                if (!this.grouped[largeItemId]) {
                    this.grouped[largeItemId] = [];
                }
                this.grouped[largeItemId].push(item);
            });

            Object.values(this.grouped).forEach((group) => {
                group.forEach((item, index) => {
                    item.end_line = index === group.length - 1;
                });
            });
        }
    },

    watch: {
        dataTools: {
            handler: function (newTool) {
                this.onHandleMapData(newTool);
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
