<script>
import { showMessage } from '@/utils/messages';
import { masterMethods, employeeMethods, checkPermission, checkPermissionName } from '@/state/helpers';
import { renderLineBreaks } from '@/utils/format';
import { PHONE_SETTING_EMPLOYEE } from '@/config/var-common.js';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

export default {
    page: {
        title: 'パルディア社員詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                password: '',
                team: {
                    id: ''
                },
                role: {
                    id: '',
                    name: ''
                },
                note: '',
                chatwork_id: '',
                account_id: null,
                chatwork_token: '',
                phone_setting_type: []
            },
            listDataMasterTeam: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...employeeMethods,
        textLineBreaks(text) {
            return renderLineBreaks(text);
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        getDetail(id) {
            this.loading = true;
            this.detailEmployee(id).then((data) => {
                this.form = data;
                if (data.phone_setting_type.length > 0) {
                    this.form.phone_setting_type = PHONE_SETTING_EMPLOYEE.filter((item) => {
                        return data.phone_setting_type.map((phone) => phone.type).includes(item.id);
                    });
                }
                if (!this.form.role) {
                    this.form.role = {
                        id: '',
                        name: ''
                    };
                }
                this.loading = false;
            });
        },
        goToEdit(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        },
        deleteObject(data) {
            Swal.fire({
                title: 'このパルディア社員を削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteEmployee(data).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/employees' }).then(() => {
                                showMessage(data.code, this.$bvToast, 'パルディア社員が削除されました。');
                            });
                        }
                    });
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form--employee form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployeePartTime.name') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.name }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployeePartTime.name_kana') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.kana_name }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployeePartTime.email') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.email }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="tokenUser">{{ $t('pageEmployee.tokenUser') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.chatwork_token }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="accountId">{{ $t('pageEmployee.accountId') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.account_id }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="chatworkId">{{ $t('pageEmployee.chatworkId') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.chatwork_id }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.role') }}</label>
                                        <div class="col-sm-2 mw--form">
                                            {{ form.role ? $t(form.role.name) : '' }}
                                        </div>
                                    </div>
                                    <div class="form-group" v-if="form.role.name !== 'part_time' && form.role.name !== 'administrator'">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">チーム</label>
                                        <div class="col-sm-6">
                                            {{ form.team ? form.team.value : '' }}
                                        </div>
                                    </div>

                                    <div class="form-group" v-if="form.phone_setting_type.length > 0">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.roleSettingPhone') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.phone_setting_type.map((item) => item.label).join('、') }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.cellphoneNumber') }}</label>
                                        <div class="col-sm-6">
                                            {{ form.phone_number }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployeePartTime.description') }}</label>
                                        <div class="col-sm-9">
                                            <p v-html="textLineBreaks(form.note)"></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/employees'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermiss(['administrator', 'instructor', 'part_time'], '')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/employee/form', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss(['administrator'], '')"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
